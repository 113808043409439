import Vue from "vue";
import Vuex from "vuex";
import { accessTokens } from "./modules/accessToken";
import { alarms } from "./modules/alarm";
import { alert } from "./modules/alert";
import { applications } from "./modules/application";
import { camOnline } from "./modules/camOnline";
import { companies } from "./modules/company";
import { configuration } from "./modules/configuration";
import { dashboards } from "./modules/dashboard";
import { downlinks } from "./modules/downlinks";
import { event } from "./modules/event";
import { gateways } from "./modules/gateway";
import { languages } from "./modules/language";
import { logs } from "./modules/log";
import { modules } from "./modules/modules";
import { mqtt } from "./modules/mqtt";
import { notifications } from "./modules/notification";
import { reports } from "./modules/report";
import { roles } from "./modules/roles";
import { scripts } from "./modules/scripts";
import { settings } from "./modules/settings";
import { signalTests } from "./modules/signalTest";
import { tag } from "./modules/tag";
import { tagData } from "./modules/tagdata";
import { users } from "./modules/user";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accessTokens,
    alarms,
    alert,
    applications,
    camOnline,
    companies,
    configuration,
    dashboards,
    downlinks,
    event,
    gateways,
    languages,
    logs,
    modules,
    mqtt,
    notifications,
    reports,
    roles,
    scripts,
    settings,
    signalTests,
    tag,
    tagData,
    users,
  },
  plugins: [createPersistedState()],
});
