<template>
  <div>
    <v-btn
      fab
      small
      dark
      color="grey darken-2"
      fixed
      right
      class="event-container-button slide-out"
      style="right: 0px"
      @click="openWindow()"
    >
      <v-icon>mdi-connection</v-icon></v-btn
    >
    <div
      class="event-container slide-out"
      :style="`width: ${window ? containerWidth : '0px'} `"
    >
      <div class="event-container-header">
        <v-btn fab small dark color="grey darken-2" @click="window = !window">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <v-autocomplete
          v-model="filterNodes"
          :items="searchTags"
          filled
          chips
          item-text="nameWithDeveui"
          return-object
          multiple
          dark
          class="pl-2"
          :placeholder="$t('tag.filter')"
          :label="$t('tag.filter')"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              color="#2e4250"
              close
              @click="data.select"
              @click:close="remove(data.item)"
              style="color: white"
              >{{ data.item.name }}</v-chip
            >
          </template>
        </v-autocomplete>
      </div>

      <div class="events-loading" v-if="window && events.length <= 0">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
        <h4>{{ $t("eventhub.waiting") }}</h4>
      </div>

      <div class="event-messages-container" v-if="window">
        <div
          class="event-message"
          v-for="event in filteredEvents"
          :key="event.id"
        >
          <div class="event-message-header">
            <h3>
              {{ event.tag.name }}
            </h3>

            <h5>
              {{ distanceAgo(event.date) }}
            </h5>
          </div>

          <span class="font-size: 1rem"></span>
          <pre
            v-html="
              event.data
                ? syntaxHighlight(JSON.stringify(event.data, undefined, 2))
                : ''
            "
          ></pre>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
} from "@microsoft/signalr";

export default {
  name: "EventHub",

  data() {
    return {
      hub: null,
      window: false,
      containerWidth: "650px",
      updateInterval: null,
      filterNodes: [],
      events: [],
    };
  },

  computed: {
    ...mapState("configuration", ["SO_API_BASE_URL"]),
    ...mapState("users", ["currentUser"]),
    ...mapState("tag", ["tags"]),

    filteredEvents() {
      if (this.filterNodes.length <= 0) return this.events;

      let deveuis = this.filterNodes.map((e) => e.deveui);
      let names = this.filterNodes.map((e) => e.name);

      return this.events.filter(
        (e) => deveuis.includes(e.tag.deveui) || names.includes(e.tag.name)
      );
    },

    searchTags() {
      return this.tags.map((e) => {
        e.nameWithDeveui = `${e.name} - ${e.deveui}`;

        return e;
      });
    },
  },

  methods: {
    ...mapActions("tag", ["getTags"]),
    ...mapActions("event", { addToEvent: "addEvent" }),

    openWindow() {
      this.window = !this.window;
    },

    addEvent(payload) {
      this.events.unshift(payload);
      if (this.events.length > 50) this.events.pop();

      this.addToEvent(payload);
    },

    syntaxHighlight(json) {
      json = json
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
      return json.replace(
        /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g,
        function (match) {
          var cls = "number";
          if (/^"/.test(match)) {
            if (/:$/.test(match)) {
              cls = "key";
            } else {
              cls = "string";
            }
          } else if (/true|false/.test(match)) {
            cls = "boolean";
          } else if (/null/.test(match)) {
            cls = "null";
          }
          return '<span class="' + cls + '">' + match + "</span>";
        }
      );
    },

    remove(item) {
      if (item == null && item.deveui == undefined) return;

      let index = this.filterNodes.findIndex((e) => e.deveui == item.deveui);
      this.filterNodes.splice(index, 1);
    },
  },

  async created() {
    if (this.tags.length <= 0) await this.getTags();

    var url = this.SO_API_BASE_URL;
    url = url + "/events";

    this.hub = new HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: () => this.currentUser.token,
      })
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.hub.on("BroadcastEvent", this.addEvent);
    await this.hub.start();

    this.updateInterval = setInterval(
      () => this.$forceUpdate(this.events),
      30000
    );
  },

  async beforeDestroy() {
    this.hub.off("BroadcastEvent", this.addEvent);
    await this.hub.stop();
    clearInterval(this.updateInterval);
  },
};
</script>

<style lang="scss">
.event-container {
  width: 0px;
  height: 100%;
  max-width: 100%;
  top: 0px;
  right: 0px;
  position: fixed;
  background-color: #1b262c;
  z-index: 9999;
  color: white;
  text-align: left;

  .event-container-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  pre {
    padding: 1rem;
  }

  .event-messages-container {
    padding: 1rem;
    max-height: 93%;
    overflow-y: scroll;

    .event-message {
      background: #24323a;
      margin: 0.5rem;
      border-radius: 20px;

      pre {
        overflow-x: auto;
      }

      .event-message-header {
        border-radius: 20px 20px 0 0;
        padding: 1rem 1rem 1rem 1rem;
        background: #2e4250;
        color: #d1d4c9;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: center;
      }
    }
  }
}

.event-container-button {
  top: 50vh;
  display: block !important;
  z-index: 999;
}

.slide-out {
  transition: all 0.75s ease;
}

.events-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.string {
  color: #33ae81;
}
.number {
  color: #dd915f;
}
.boolean {
  color: #95c8f0;
}
.null {
  color: #dc5b57;
}
.key {
  color: #4a8ddc;
}
</style>