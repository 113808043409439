import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
const DashboardRepository = RepositoryFactory.get("dashboard");

export const dashboards = {
  namespaced: true,
  state: {
    currentDashboard: {},
    dashboards: [],
    defaultDashboard: {},
    publicDashboard: null,
    publicDashboards: [],
    categories: [],
    hasDefault: false,
    status: {
      loading: false,
    },
  },

  actions: {
    async getDashboards({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await DashboardRepository.getDashboards(
        user,
        rootState.users.userCompany.companyId
      )
        .then((dashboards) => {
          commit("SET_DASHBOARDS", dashboards.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async duplicate({ dispatch, commit, rootState }, { dashboardId, payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await DashboardRepository.duplicate(user, dashboardId, payload)
        .then(() => {
          dispatch("getDashboards");
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getDashboard({ dispatch, commit, rootState }, { dashboardId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await DashboardRepository.getDashboard(user, dashboardId)
        .then((dashboard) => {
          commit("SET_DASHBOARD", dashboard.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });
    },

    async setDashboard({ commit }, dashboard) {
      commit("SET_DASHBOARD", dashboard);
    },

    async create({ dispatch, commit, rootState }, { payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await DashboardRepository.createForCompany(
        user,
        rootState.users.userCompany.companyId,
        payload
      )
        .then(() => {
          dispatch("getDashboards");
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async update({ dispatch, commit, rootState }, { dashboardId, payload }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await DashboardRepository.update(user, dashboardId, payload)
        .then(() => {
          dispatch("getDashboards");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async delete({ dispatch, commit, rootState }, dashboardId) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await DashboardRepository.delete(user, dashboardId)
        .then(() => {
          dispatch("getDashboards");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });

      await dispatch("getPublicDashboards");
    },

    async setDefault({ dispatch, commit, rootState }, { dashboardId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      await DashboardRepository.setDefault(user, dashboardId)
        .then(() => {
          dispatch("getDashboards");
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getDefault({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      await DashboardRepository.getDefault(user)
        .then((dashboard) => {
          commit("SET_DEFAULT", dashboard.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async createPublicDashboard(
      { dispatch, commit, rootState },
      { dashboardId, expiresAt }
    ) {
      var user = rootState.users.currentUser;
      await DashboardRepository.createPublicDashboard(
        user,
        rootState.users.userCompany.companyId,
        dashboardId,
        expiresAt
      )
        .then((dashboard) => {
          commit("SET_PUBLIC_DASHBOARD", dashboard.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async getPublicDashboards({ dispatch, commit, rootState }) {
      var user = rootState.users.currentUser;

      await DashboardRepository.getPublicDashboards(
        user,
        rootState.users.userCompany.companyId
      )
        .then((dashboard) => commit("SET_PUBLIC_DASHBOARDS", dashboard.data))
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
        });
    },

    async getPublicDashboard({ commit, rootState }, id) {
      var user = rootState.users.currentUser;
      if (user && user.userId != undefined) return;

      await DashboardRepository.getPublicDashboard(id).then((dashboard) => {
        commit("SET_PUBLIC_DASHBOARD", dashboard.data);
      });
    },

    async deletePublicDashboard({ dispatch, rootState }, id) {
      var user = rootState.users.currentUser;

      await DashboardRepository.deletePublicDashboard(user, id)
        .then(() => {
          dispatch("getPublicDashboards");
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async getDashboardCategories({ commit, rootState, dispatch }) {
      var user = rootState.users.currentUser;

      await DashboardRepository.getDashboardCategories(
        user,
        rootState.users.userCompany.companyId
      )
        .then((d) => {
          commit("SET_DASHBOARD_CATEGORIES", d.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
        });
    },

    async createDashboardCategories({ commit, rootState, dispatch }, payload) {
      var user = rootState.users.currentUser;
      commit("LOADING", true);
      await DashboardRepository.createDashboardCategories(
        user,
        rootState.users.userCompany.companyId,
        payload
      )
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
        });
      commit("LOADING", false);
    },

    async updateDashboardCategories(
      { commit, rootState, dispatch },
      { categoryId: categoryId, payload: payload }
    ) {
      var user = rootState.users.currentUser;
      commit("LOADING", true);
      await DashboardRepository.updateDashboardCategories(
        user,
        categoryId,
        payload
      )
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
        });
      commit("LOADING", false);
    },

    async updateDashboardCategoryIndexes(
      { commit, rootState, dispatch },
      payload
    ) {
      var user = rootState.users.currentUser;
      commit("LOADING", true);

      await DashboardRepository.updateDashboardIndexes(user, payload)
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
        });

      commit("LOADING", false);
    },

    async removeDashboardCategory({ commit, rootState, dispatch }, categoryId) {
      var user = rootState.users.currentUser;
      commit("LOADING", true);

      await DashboardRepository.removeDashboardIndexes(user, categoryId)
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          } else if (res.response.status === 400) {
            dispatch("alert/error", i18n.t(res.response.data), { root: true });
          }
        });

      commit("LOADING", false);
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    SET_DASHBOARD_CATEGORIES(state, categories) {
      state.categories = categories;
    },

    SET_PUBLIC_DASHBOARD(state, publicDashboard) {
      state.publicDashboard = publicDashboard;
    },

    SET_PUBLIC_DASHBOARDS(state, publicDashboards) {
      state.publicDashboards = publicDashboards;
    },

    DELETE_APPLICATION(state) {
      state.currentDashboard = {};
    },

    SET_DEFAULT(state, dashboard) {
      if (Object.entries(dashboard).length !== 0) {
        state.defaultDashboard = dashboard;
        state.currentDashboard = dashboard;
        state.hasDefault = true;
      } else {
        state.currentDashboard = {};
        state.defaultDashboard = {};
        state.hasDefault = false;
      }
    },

    SET_DASHBOARDS(state, dashboards) {
      state.dashboards = dashboards;
    },

    SET_DASHBOARD(state, dashboard) {
      state.currentDashboard = dashboard;
    },

    // eslint-disable-next-line
    RESET_STATE(state) {
      state = {
        currentDashboard: {},
        dashboards: [],
        status: {
          loading: false,
        },
      };
    },
  },
};
