import { format, formatDistance } from "date-fns";
import i18n from "@/plugins/i18n.js";
import { sv } from "date-fns/locale";

export default {
  methods: {
    humanDate(date, formatString) {
      return date !== undefined && date !== null
        ? format(
            new Date(date),
            formatString !== undefined ? formatString : "dd MMM - HH:mm"
          )
        : "N/A";
    },

    timeFromDays(days) {
      return days * 24 * 60 * 60 * 1000;
    },

    timeFromHours(hours) {
      return hours * 60 * 60 * 1000;
    },

    timeFromMinutes(minutes) {
      return minutes * 60 * 1000;
    },

    timeWithinMinutes(date1, minutes, date2) {
      if (date2 == undefined) date2 = new Date();
      return date2 - new Date(date1) < this.timeFromMinutes(minutes);
    },

    timeWithinHours(date, hours) {
      return new Date() - new Date(date) < this.timeFromHours(hours);
    },

    timeWithinDays(date, days) {
      return new Date() - new Date(date) < this.timeFromDays(days);
    },

    distanceAgo(date) {
      if (date == null) return "";

      let options = {};
      if (i18n.locale == "sv") options = { locale: sv };

      return formatDistance(new Date(date), new Date(), options);
    },

    toApiDate(date) {
      return this.humanDate(date, "yyyy-MM-dd HH:mm:ss");
    },
  },
};
