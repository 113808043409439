import Repository from "../Repository";
import { getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/tag";
const decoderResource = "/decoder";

export default {
  get(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}`, config);
  },

  create(user, applicationId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(
      `/applications/${applicationId}/tag`,
      payload,
      config
    );
  },

  update(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${payload.deveui}`, payload, config);
  },

  delete(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${tagId}`, config);
  },

  getById(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${tagId}`, config);
  },

  getByCompanyId(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/company/${companyId}`, config);
  },

  getKeys(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${tagId}/keys`, config);
  },

  uploadImage(user, tagId, image) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${tagId}/image`, image, config);
  },

  deleteImage(user, tagId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${tagId}/image`, config);
  },

  setDefaultKey(user, tagId, key) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${tagId}/default/${encodeURIComponent(key)}`, null, config);
  },

  getTagPool(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/pool`, config);
  },

  updateTagPoolStatus(user, deveui) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/pool/${deveui}`, null, config);
  },

  addTagKey(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/key/add`, payload, config);
  },

  updateTagKey(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/key/update`, payload, config);
  },

  deleteTagKey(user, deveui, key) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/key/delete/${deveui}/${encodeURIComponent(key)}`, config);
  },

  getKeyLabel(user, deveui, key) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/key/label/${deveui}/${encodeURIComponent(key)}`, config);
  },

  getDecoders(user) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${decoderResource}`, config);
  },

  setData(user, tagId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${tagId}/tagdata`, payload, config);
  },

  getDetails(user, tagId) {
    var config = {
      headers: getAuthHeader(user)
    }

    return Repository.get(`${resource}/detail/${tagId}`, config);
  }
};
