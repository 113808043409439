import Vue from "vue";
import Router from "vue-router";
import store from "@/stores/store";
import i18n from "@/plugins/i18n.js";
import restrictHelper from "./_helpers/RestrictHelper.js";

// Import components
// const Home = () => import("@/views/Home.vue");
const Home = () => import("@/views/Landing.vue");
const CommonParent = () => import("@/views/CommonParent.vue");
const CleanDashboard = () => import("@/views/CleanDashboard.vue");
const AdminDashboard = () => import("@/views/AdminDashboard.vue");

const Company = () => import("@/views/company/Company.vue");
const CompanyUsers = () => import("@/components/company/Users.vue");
const CompanyEdit = () => import("@/components/company/Edit.vue");
const CompanyCreate = () => import("@/components/company/Create.vue");
const CreateUser = () => import("@/components/company/CreateUser.vue");
const CompanyApps = () => import("@/components/company/Applications.vue");

const UserCompanyRegister = () => import("@/components/user/Register");
const EditUser = () => import("@/components/user/Detail");

const ForgotPassword = () => import("@/views/ForgotPassword.vue");
const ForgotPasswordComponent = () =>
  import("@/components/password/ForgotPasswordComponent.vue");
const NewPassword = () => import("@/components/password/NewPassword.vue");

const Application = () => import("@/views/Application.vue");
const ApplicationCreate = () => import("@/components/application/Create.vue");
const ApplicationEdit = () => import("@/components/application/Edit.vue");

const Tag = () => import("@/views/Tag.vue");
const TagList = () => import("@/components/tag/Tag.vue");
const TagCreate = () => import("@/components/tag/Create.vue");
const TagPool = () => import("@/components/tag/TagPool.vue");

const TagDetail = () => import("@/views/TagDetail.vue");

const Alarm = () => import("@/views/Alarm.vue");
const AlarmCreate = () => import("@/components/alarm/Create.vue");
const AlarmEdit = () => import("@/components/alarm/Edit.vue");
const AlarmList = () => import("@/components/alarm/AlarmList.vue");
const ActiveAlarms = () => import("@/components/alarm/ActiveAlarms.vue");

const Login = () => import("@/views/Login.vue");
const Register = () => import("@/views/Register.vue");
const Logout = () => import("@/views/Logout.vue");

const ScriptView = () => import("@/views/Script.vue");
const ScriptList = () => import("@/components/script/ScriptList.vue");
const ScriptCreate = () => import("@/components/script/ScriptCreate.vue");
const ScriptEdit = () => import("@/components/script/ScriptEdit.vue");

const Report = () => import("@/views/Report.vue");
const ReportList = () => import("@/components/report/ReportList.vue");
const ReportCreate = () => import("@/components/report/ReportCreate.vue");
const ReportEdit = () => import("@/components/report/ReportEdit.vue");
const DashboardModule = () => import("@/components/common/ModuleGrid");
const DashboardPublic = () => import("@/views/DashboardPublic.vue");

const Contact = () => import("@/views/Contact.vue");
const News = () => import("@/views/News.vue");
const SettingView = () => import("@/views/Setting.vue");
const Settings = () => import("@/components/setting/SettingEdit.vue");

const SingalTest = () => import("@/views/SignalTest.vue");
const PeekSignalTest = () =>
  import("@/components/signaltest/PeekSignalTest.vue");

const GatewayView = () => import("@/views/Gateway.vue");
const GatewayList = () => import("@/components/gateway/GatewayList.vue");
const GatewayCreate = () => import("@/components/gateway/CreateGateway.vue");

const RoleContainer = () => import("@/views/RolesContainer.vue");
const RoleList = () => import("@/components/roles/RoleList.vue");
const RoleCreate = () => import("@/components/roles/RoleCreate.vue");
const RoleEdit = () => import("@/components/roles/RoleEdit.vue");

import { Roles } from "@/_helpers/Role";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: { authorize: [] },
    },

    {
      path: "/settings",
      component: SettingView,
      meta: { authorize: Roles.CompanyOrUser },
      children: [
        {
          path: "",
          name: "setting/edit",
          component: Settings,
        },
      ],
    },

    {
      path: "/dashboard",
      name: "dashboardChooser",
      component: CleanDashboard,
      meta: { permit: "Dashboard.View" },
    },

    {
      path: "/dashboard/:id",
      component: DashboardModule,
      meta: { permit: "Dashboard.View" },
    },

    {
      path: "/public/:id",
      component: DashboardPublic,
      meta: { permit: "ALL" },
    },

    {
      path: "/alarm",
      component: Alarm,
      children: [
        {
          path: "",
          name: "alarm",
          component: AlarmList,
          meta: { permit: "Alarm.View" },
        },
        {
          path: "active",
          component: ActiveAlarms,
          meta: { permit: "Alarm.View" },
        },
        {
          path: "create",
          component: AlarmCreate,
          meta: { permit: "Alarm.Create" },
        },
        {
          path: ":id",
          component: AlarmEdit,
          meta: { permit: "Alarm.Update" },
        },
      ],
    },

    {
      path: "/gateways",
      component: GatewayView,
      children: [
        {
          path: "",
          component: GatewayList,
          meta: { permit: "Gateway.View" },
        },
        {
          path: "create",
          component: GatewayCreate,
          meta: { permit: "Gateway.Create" },
        },
      ],
    },

    {
      path: "/signaltest",
      component: CommonParent,
      meta: { permit: "SignalTest.View" },
      children: [
        {
          path: "",
          component: SingalTest,
          meta: { permit: "SignalTest.View" },
        },
        {
          path: "peek/:id",
          component: PeekSignalTest,
          meta: { permit: "SignalTest.View" },
        },
      ],
    },

    {
      path: "/script",
      component: ScriptView,
      children: [
        {
          path: "",
          component: ScriptList,
          meta: { permit: "Script.View" },
        },

        {
          path: "create",
          component: ScriptCreate,
          meta: { permit: "Script.Create" },
        },

        {
          path: ":id",
          component: ScriptEdit,
          meta: { permit: "Script.Update" },
        },
      ],
    },

    {
      path: "/company",
      component: Company,
      children: [
        {
          path: "",
          component: CompanyUsers,
          meta: { permit: "Company.View" },
        },
        {
          path: "edit",
          component: CompanyEdit,
          meta: { permit: "Company.Update" },
        },
        {
          path: "create",
          component: CompanyCreate,
          meta: { permit: "Company.Create" },
        },
        {
          path: "applications",
          component: CompanyApps,
          meta: { permit: "Application.View" },
        },
        {
          path: "usercreate",
          component: CreateUser,
          meta: { permit: "User.Create" },
        },
        {
          path: "user/:id",
          component: EditUser,
          meta: { permit: "User.Update" },
        },
      ],
    },

    {
      path: "/forgot",
      component: ForgotPassword,
      children: [
        {
          path: "",
          component: ForgotPasswordComponent,
        },
        {
          path: ":id",
          component: NewPassword,
        },
      ],
    },

    {
      path: "/application",
      component: Application,
      children: [
        {
          path: "",
          component: CompanyApps,
          meta: { permit: "Application.View" },
        },
        {
          path: "create",
          component: ApplicationCreate,
          meta: { permit: "Application.Create" },
        },
        {
          path: ":id",
          component: ApplicationEdit,
          meta: { permit: "Application.Update" },
        },
      ],
    },

    {
      path: "/superadmin",
      component: AdminDashboard,
      meta: { permit: "ALL" },
    },

    {
      path: "/report",
      component: Report,
      children: [
        {
          path: "",
          component: ReportList,
          meta: { permit: "Report.View" },
        },
        {
          path: "create",
          component: ReportCreate,
          meta: { permit: "Report.Create" },
        },

        {
          path: ":id",
          component: ReportEdit,
          meta: { permit: "Report.Update" },
        },
      ],
    },

    {
      path: "/tag",
      component: Tag,
      children: [
        {
          path: "",
          component: TagList,
          meta: { permit: "Tag.View" },
        },
        {
          path: "create",
          component: TagCreate,
          meta: { permit: "Tag.Create" },
        },
        {
          path: "create/:deveui",
          component: TagCreate,
          meta: { permit: "Tag.Update" },
        },
        {
          path: "pool",
          component: TagPool,
          meta: { permit: "TagPool.View" },
        },
      ],
    },

    {
      path: "/roles",
      component: RoleContainer,
      children: [
        {
          path: "",
          component: RoleList,
          meta: { permit: "Roles.View" },
        },
        {
          path: "create",
          component: RoleCreate,
          meta: { permit: "Roles.Create" },
        },

        {
          path: "edit/:id",
          component: RoleEdit,
          meta: { permit: "Roles.Update" },
        },
      ],
    },

    {
      path: "/tagdetails/:deveui",
      component: TagDetail,
      meta: { permit: "Tag.View" },
    },

    {
      path: "/register",
      component: Register,
      meta: { permit: "Company.Create" },
      children: [
        {
          path: "",
          component: UserCompanyRegister,
          meta: { permit: "Company.Create" },
        },
      ],
    },

    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
    },

    {
      path: "/contact",
      name: "Contact",
      component: Contact,
    },

    {
      path: "/news",
      name: "News",
      component: News,
    },
  ],

  duplicateNavigationPolicy: "reload",
});

router.beforeEach((to, from, next) => {
  if (
    store.state.languages !== undefined &&
    store.state.languages.language &&
    store.state.languages.language !== i18n.locale
  ) {
    i18n.locale = store.state.languages.language;
    next();
  } else if (
    store.state.languages === undefined ||
    !store.state.languages.language
  ) {
    store
      .dispatch("languages/setLanguage", navigator.languages, { root: true })
      .then(() => {
        i18n.locale = store.state.languages.language;
        next();
      });
  }

  if (to.meta.authorize) {
    if (restrictHelper.methods.permitted(to.meta.permit)) {
      next();
    } else {
      return;
    }
  }

  next();
});

export default router;
