import { RepositoryFactory } from "../../api/RepositoryFactory";

import i18n from "@/plugins/i18n";
const UserRepository = RepositoryFactory.get("users");
const RoleRepository = RepositoryFactory.get("roles");

export const users = {
  namespaced: true,
  state: {
    users: [],
    permissions: [],
    user: {},
    currentUser: {},
    userCompany: {},
    credentials: { email: "", password: "" },
    hasCompany: false,
    status: {
      loading: false,
      loggedIn: false,
    },
  },

  actions: {
    async get({ commit, dispatch, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await UserRepository.get(user).then(
        (users) => {
          commit("GET_USER", users.data);
          commit("LOADING", false);
        },
        (res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        }
      );

      commit("LOADING", false);
    },

    async getById({ commit, dispatch, rootState }, userId) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await UserRepository.getById(user, userId)
        .then((users) => {
          commit("GET_USER", users.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });
      commit("LOADING", false);
    },

    async getAll({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await UserRepository.getAll(user).then(
        (users) => {
          commit("GET_USERS", users.data);
          commit("LOADING", false);
        },
        (res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        }
      );
      commit("LOADING", false);
    },

    async getUserCompany({ dispatch, commit, rootState }) {
      commit("LOADING", true);
      var user = rootState.users.currentUser;

      await UserRepository.getCompany(user).then(
        (company) => {
          commit("GET_USER_COMPANY", company.data);
          commit("LOADING", false);
          dispatch("languages/setLanguage", company.data.country, {
            root: true,
          });
        },
        (res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        }
      );
    },

    setUserCompany({ dispatch, commit }, { company }) {
      commit("LOADING", true);

      commit("GET_USER_COMPANY", company);
      dispatch("languages/setLanguage", company.country, {
        root: true,
      });

      commit("LOADING", false);
    },

    async setUserByPublicDashboard({ commit }, dashboard) {
      commit("LOGIN_USER", {
        token: dashboard.token,
        role: "PublicDashboard",
      });
      commit("GET_USER_COMPANY", dashboard.dashboard.company);
    },

    async editUser({ dispatch, commit, rootState, state }, userPayload) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await UserRepository.edit(user, userPayload.userId, userPayload)
        .then((user) => {
          commit("EDIT_USER", user.data);
          commit("LOADING", false);

          if (state.currentUser.userId === user.data.userId) {
            dispatch("login", state.credentials);
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("user.updateFailed"), { root: true });
          commit("LOADING", false);
        });
    },

    async login({ dispatch, commit }, { email, password }) {
      commit("SET_CREDENTIALS", { email: email, password: password });

      commit("LOADING", true);
      let success = false;
      await UserRepository.login(email, password)
        .then(async (user) => {
          commit("LOGIN_USER", user.data);
          await dispatch("getUserCompany");
          await dispatch("getUserPermissionsByCurrentUser");
          success = true;
          commit("LOADING", false);
        })
        .catch((res) => {
          if (!res.response) {
            throw new Error(i18n.t("common.errors.backendConnection"));
          } else if (res.response.status == 409) {
            // The license is probably not valid
            throw new Error(res.response.data);
          }

          commit("LOGIN_FAILED");
          commit("LOADING", false);
          dispatch("alert/error", i18n.t("user.signInFailed"), { root: true });
        });

      return success;
    },

    async getUserPermissionsByCurrentUser({ commit, rootState }) {
      await RoleRepository.getUserPermissionsByUserId(
        rootState.users.currentUser
      ).then((d) => commit("SET_PERMISSIONS", d.data));
    },

    async logout({ dispatch, commit }) {
      commit("LOGOUT_USER");
      dispatch("companies/resetState", null, { root: true });
      dispatch("tag/resetState", null, { root: true });
      dispatch("applications/resetState", null, { root: true });
      dispatch("tagData/resetState", null, { root: true });
      dispatch("alarms/resetState", null, { root: true });
      commit("LOADING", false);
    },

    async updateRole(
      { dispatch, commit, rootState },
      { role: role, targetUserId: targetUserId }
    ) {
      commit("LOADING", false);

      var user = rootState.users.currentUser;

      await UserRepository.updateRole(user, targetUserId, role)
        .then(() => {
          dispatch("companies/getCompanyUsers", null, { root: true });
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), { root: true });
        });
    },

    async createUserAndCompany({ dispatch, commit, rootState }, payload) {
      commit("LOADING", true);
      await UserRepository.createUserWithCompany(
        rootState.users.currentUser,
        payload
      )
        .then(() => {
          dispatch("alert/success", i18n.t("common.created"), {
            root: true,
          });
        })
        .catch((error) => {
          if (error.response.status === 409) {
            throw new Error(error.response.data);
          } else {
            dispatch("alert/error", i18n.t("common.error"), {
              root: true,
            });
          }
        });
    },

    async delete({ dispatch, commit, rootState }, userId) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      if (user.userId !== userId) {
        await UserRepository.delete(user, userId)
          .then(() => {
            dispatch("alert/success", i18n.t("common.deleted"), {
              root: true,
            });
            commit("LOADING", false);
          })
          .catch((res) => {
            if (res.response.status === 401) {
              dispatch(
                "users/login",
                {
                  email: rootState.users.credentials.email,
                  password: rootState.users.credentials.password,
                },
                { root: true }
              );
            }

            dispatch("alert/error", i18n.t("common.error"), {
              root: true,
            });
          });
      }
    },

    async forgotPassword({ dispatch, commit }, email) {
      commit("LOADING", true);
      await UserRepository.forgotPassword(email)
        .then(() => {
          commit("LOADING", false);
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
      commit("LOADING", false);
    },

    async forgotPasswordUpdate({ dispatch, commit }, { guid, password }) {
      commit("LOADING", true);
      await UserRepository.forgotPasswordUpdate(guid, password)
        .then(() => {
          commit("LOADING", false);
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
      commit("LOADING", false);
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    GET_USER(state, user) {
      state.user = user;
    },

    GET_USERS(state, users) {
      state.users = users;
    },

    GET_USER_COMPANY(state, company) {
      state.userCompany = company;
      state.hasCompany = true;
    },

    SET_CREDENTIALS(state, credentials) {
      state.credentials = credentials;
    },

    EDIT_USER(state, user) {
      state.user = user;
    },

    LOGIN_USER(state, user) {
      state.currentUser = user;
      state.status.loggedIn = true;
    },

    LOGIN_FAILED(state) {
      state.status.loggedIn = false;
    },

    SET_PERMISSIONS(state, val) {
      state.permissions = val;
    },

    // eslint-disable-next-line
    LOGOUT_USER(state) {
      state.users = [];
      state.user = [];
      state.currentUser = {};
      state.userCompany = {};
      state.credentials = { email: "", password: "" };
      state.hasCompany = false;
      state.permissions = [];
      state.status = {
        loading: false,
        loggedIn: false,
      };
    },
  },

  getters: {
    companyId(state) {
      return state.userCompany.companyId;
    },

    companyName(state) {
      return state.userCompany.name;
    },

    permissions(state) {
      return state.permissions;
    },
  },
};
