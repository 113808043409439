<template>
  <div class="tree-container">
    <v-row style="max-width: 100%">
      <v-col cols="12" md="9" xs="9">
        <sl-vue-tree
          v-model="categories"
          @dragend="() => {}"
          @nodecontextmenu="onNodeRightClicked"
          ref="tree"
          @input="updateIndexes"
        >
          <template slot="title" slot-scope="{ node }">
            <span class="item-icon">
              <v-icon
                :color="
                  node.isLeaf
                    ? 'green'
                    : node.data.color
                    ? node.data.color
                    : 'primary'
                "
                >{{ node.data.icon }}</v-icon
              >
            </span>

            <span
              v-if="node.isLeaf"
              @click="goToDashboard(node.data.dashboardId)"
              :href="'dashboard/' + node.data.dashboardId"
            >
              {{ node.title }}</span
            >
            <span v-else> {{ node.title }}</span>
          </template>

          <template slot="toggle" slot-scope="{ node }">
            <span v-if="!node.isLeaf">
              <v-icon v-if="node.isExpanded">mdi-chevron-down</v-icon>
              <v-icon v-if="!node.isExpanded">mdi-chevron-right</v-icon>
            </span>
          </template>
        </sl-vue-tree>
      </v-col>

      <v-col cols="12" md="3" xs="3">
        <v-switch
          v-model="editMode"
          inset
          :label="$t('common.edit')"
          v-if="permitted('Dashboard.Update')"
          class="mr-2 ml-2"
        ></v-switch>
      </v-col>
    </v-row>

    <v-menu
      v-model="showMenu"
      :position-x="x"
      :position-y="y"
      absolute
      offset-y
      v-if="permitted('Dashboard.Update')"
    >
      <v-list>
        <v-list-item
          link
          @click="item.action"
          v-for="(item, index) in displayActions"
          :key="index"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog v-model="showCreateDialog" max-width="600" persistent>
      <v-card>
        <v-card-title>
          <span>{{ $t("dashboard.categories.create") }}</span>
        </v-card-title>
        <v-form
          :lazy-validation="true"
          @submit.prevent="handleCreate"
          v-model="valid"
        >
          <v-card-text>
            <v-text-field
              v-model="payload.title"
              :label="$t('dashboard.categories.title')"
            ></v-text-field>
          </v-card-text>

          <v-card-text>
            <v-color-picker
              v-model="color"
              dot-size="25"
              hide-canvas
              hide-mode-switch
              show-swatches
              mode="hexa"
              swatches-max-height="200"
            ></v-color-picker>
          </v-card-text>

          <v-card-text>
            <IconSelector
              :icon="payload.icon"
              :open="true"
              :color="payload.color"
              v-on:update="
                (v) => {
                  payload.icon = v;
                }
              "
            />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary lighten-1"
              text
              @click="showCreateDialog = false"
              x-large
              >{{ $t("common.close") }}</v-btn
            >
            <v-btn color="primary" text type="submit" x-large>{{
              $t("common.save")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showRemoveDialog" max-width="450">
      <v-card>
        <v-card-title class="headline">
          {{ $t("common.verify") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary lighten-1"
            text
            @click="showRemoveDialog = false"
            >{{ $t("common.close") }}</v-btn
          >

          <v-btn color="primary" text @click="deleteConfirmed">{{
            $t("common.yes")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDashboardCreateOrCopy" max-width="450">
      <v-card>
        <v-card-title>
          <span>{{ $t("dashboard.createTitleClean") }}</span>
        </v-card-title>
        <v-form
          :lazy-validation="true"
          @submit.prevent="handleDashboardCopyOrCreate"
          v-model="valid"
        >
          <v-card-text>
            <v-text-field
              v-model="dashboardName"
              :label="$t('dashboard.fields.name')"
            ></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary lighten-1"
              text
              @click="showDashboardCreateOrCopy = false"
              x-large
              >{{ $t("common.close") }}</v-btn
            >
            <v-btn color="primary" text type="submit" x-large>{{
              $t("common.save")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import IconSelector from "@/components/common/IconSelector";
import { Roles } from "@/_helpers/Role";
import SlVueTree from "sl-vue-tree";

export default {
  name: "DashboardCategoryViewer",
  data() {
    return {
      x: 0,
      y: 0,
      valid: false,
      edit: false,
      copy: false,
      showMenu: false,
      isDashboard: false,
      showCreateDialog: false,
      showRemoveDialog: false,
      showDashboardCreateOrCopy: false,
      editMode: false,
      color: "#2196F3",
      dashboardName: "",
      payload: {
        title: "",
        icon: "mdi-shape-outline",
        color: "#2196F3",
      },
      currentNode: {},
      categories: [],
      actions: [
        {
          title: this.$t("common.actions.createCategory"),
          action: this.createAction,
          display: [true, true],
        },
        {
          title: this.$t("common.actions.editCategory"),
          action: this.editAction,
          display: [true, false],
        },
        {
          title: this.$t("common.actions.createDashboard"),
          action: this.createDashboardAction,
          display: [true, true],
        },
        {
          title: this.$t("common.actions.copyDashboard"),
          action: this.copyDashboardAction,
          display: [false, true],
        },
        {
          title: this.$t("common.actions.remove"),
          action: this.removeAction,
          display: [true, true],
        },
      ],
    };
  },

  computed: {
    ...mapState("dashboards", { storeCategories: "categories" }),

    displayActions() {
      return this.actions.filter((element) =>
        this.shouldDisplay(element.display)
      );
    },

    Roles() {
      return Roles;
    },
  },

  methods: {
    ...mapActions("dashboards", [
      "getDashboardCategories",
      "createDashboardCategories",
      "updateDashboardCategories",
      "updateDashboardCategoryIndexes",
      "removeDashboardCategory",
      "delete",
      "create",
      "duplicate",
    ]),

    createAction() {
      this.color = "#2196F3";
      this.showCreateDialog = true;
      this.showMenu = false;
      this.edit = false;
    },

    editAction() {
      this.payload.title = this.currentNode.title;
      this.payload.icon = this.currentNode.data.icon;
      this.payload.color = this.currentNode.data.color;
      this.color = this.payload.color;
      this.showCreateDialog = true;
      this.showMenu = false;
      this.edit = true;
    },

    async removeAction() {
      this.showRemoveDialog = true;
    },

    createDashboardAction() {
      this.showDashboardCreateOrCopy = true;
      this.showMenu = false;
      this.copy = false;
    },

    copyDashboardAction() {
      this.showDashboardCreateOrCopy = true;
      this.showMenu = false;
      this.copy = true;
    },

    shouldDisplay(displayArr) {
      if (this.isDashboard) {
        return displayArr[1];
      }

      return displayArr[0];
    },

    async updateIndexes() {
      if (this.permitted("Dashboard.Update") && this.editMode)
        await this.updateDashboardCategoryIndexes(this.categories);
    },

    async handleCreate() {
      // Add parent node if its a create action only
      if (!this.currentNode.isLeaf && !this.edit)
        this.payload.parentGuid = this.currentNode.data.dashboardCategoryId;

      if (!this.edit) await this.createDashboardCategories(this.payload);
      else
        await this.updateDashboardCategories({
          categoryId: this.currentNode.data.dashboardCategoryId,
          payload: this.payload,
        });

      this.payload = {
        title: "",
        icon: "mdi-shape-outline",
      };

      await this.getCategoryItems();
      this.showCreateDialog = false;
      this.edit = false;
    },

    async handleDashboardCopyOrCreate() {
      if (this.copy) {
        if (
          this.currentNode.data.dashboardId <= 0 ||
          this.currentNode.data.dashboardId == null
        ) {
          return;
        }

        await this.duplicate({
          dashboardId: this.currentNode.data.dashboardId,
          payload: { name: this.dashboardName },
        });
      } else {
        await this.create({
          payload: {
            name: this.dashboardName,
            categoryId: this.currentNode.data.dashboardCategoryId,
          },
        });
      }

      await this.getCategoryItems();
      this.showDashboardCreateOrCopy = false;
      this.copy = false;
    },

    async deleteConfirmed() {
      if (this.isDashboard) {
        await this.delete(this.currentNode.data.dashboardId);
      } else {
        await this.removeDashboardCategory(
          this.currentNode.data.dashboardCategoryId
        );
      }

      await this.getCategoryItems();
      this.showRemoveDialog = false;
      this.showCreateDialog = false;
      this.edit = false;
    },

    onNodeRightClicked(node, e) {
      e.preventDefault();
      // Get the tree reference
      const tree = this.$refs.tree;
      //use the API to select the node
      tree.select(node.path);

      this.currentNode = node;
      this.isDashboard = node.isLeaf;
      this.x = e.x;
      this.y = e.y;
      this.showMenu = true;
    },

    async getCategoryItems() {
      await this.getDashboardCategories();
      this.categories = this.storeCategories.slice(0);
      this.updateDraggable(this.categories);
    },

    updateDraggable(categories) {
      if (categories == null) return;

      for (var c of categories) {
        c.isDraggable = this.editMode;
        c.isSelectable = this.editMode;
        this.updateDraggable(c.children);
      }
    },

    goToDashboard(dashboardId) {
      this.$router.push(`/dashboard/${dashboardId}`);
      if (this.isInsideDashboard) this.$router.go();
      this.$emit("close", true);
    },
  },

  async created() {
    await this.getCategoryItems();
  },

  components: {
    SlVueTree,
    IconSelector,
  },

  watch: {
    color(v) {
      if (v != null) {
        if (v.hex == undefined) this.payload.color = v;
        else this.payload.color = v.hex;
      }
    },

    editMode(v, o) {
      if (v != o) {
        this.updateDraggable(this.categories);
      }
    },
  },
};
</script>

<style>
.sl-vue-tree {
  position: relative;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.sl-vue-tree.sl-vue-tree-root {
  /* border: 1px solid rgb(9, 22, 29);
    background-color: rgb(9, 22, 29); */
  /* color: rgba(255, 255, 255, 0.5); */
  border-radius: 3px;
}

.sl-vue-tree-root > .sl-vue-tree-nodes-list {
  overflow: hidden;
  position: relative;
  padding-bottom: 4px;
}

.sl-vue-tree-selected > .sl-vue-tree-node-item {
  /* background-color: #13242d;
    color: white; */
}

.sl-vue-tree-node-item:hover,
.sl-vue-tree-node-item.sl-vue-tree-cursor-hover {
  color: rgba(173, 173, 173, 0.5);
}

.sl-vue-tree-node-item {
  position: relative;
  display: flex;
  flex-direction: row;

  padding-left: 10px;
  padding-right: 10px;
  line-height: 28px;
  border: 1px solid transparent;
}

.sl-vue-tree-node-item.sl-vue-tree-cursor-inside {
  border: 1px solid rgba(173, 173, 173, 0.5);
}

.sl-vue-tree-gap {
  width: 25px;
  min-height: 1px;
}

.sl-vue-tree-toggle {
  display: inline-block;
  text-align: left;
  width: 20px;
}

.sl-vue-tree-sidebar {
  margin-left: auto;
}

.sl-vue-tree-cursor {
  position: absolute;
  border: 1px solid rgba(214, 214, 214, 0.5);
  height: 1px;
  width: 100%;
}

.sl-vue-tree-drag-info {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0.5;
  margin-left: 20px;
  padding: 5px 10px;
}
</style>