import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
import { Roles } from "@/_helpers/Role";
import ApiHelper from "@/_helpers/apiHelper.js";
import companyRepository from "../../api/repositories/companyRepository";
import DecoderHelper from "@/_helpers/DecoderHelper";

const TagRepository = RepositoryFactory.get("tag");

export const tag = {
  namespaced: true,
  state: {
    currentTag: {},
    currentLabel: {},
    tagsWithInformation: [],
    tags: [],
    tagsWithKeys: [],
    batteryStatuses: [],
    tagsInPool: [],
    detail: {},
    status: {
      loading: false,
    },
    apiErrors: [],
    decoders: [],
  },

  actions: {
    async getTags({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      await TagRepository.getByCompanyId(
        user,
        rootState.users.userCompany.companyId
      )
        .then((tags) => {
          commit("GET_TAGS", tags.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });

      commit("LOADING", false);
    },

    async getTagAsync({ commit, rootState }, id) {
      var user = rootState.users.currentUser;

      let data = {};
      await TagRepository.getById(user, id)
        .then((tag) => {
          commit("CREATED_TAG", tag.data);
          data = tag.data;
        })
        .catch(() => {
          commit("LOADING", false);
        });

      return data;
    },

    async createTag(
      { dispatch, commit, rootState },
      { applicationId, payload }
    ) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await TagRepository.create(user, applicationId, payload)
        .then((tag) => {
          commit("CREATED_TAG", tag.data);
          commit("CLEAR_ERRORS");
          dispatch("getTags", user);
          dispatch("alert/success", i18n.t("common.created"), {
            root: true,
          });
        })
        .catch((res) => {
          commit("SET_ERRORS", res.response.data.errors);

          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );

            dispatch("alert/error", i18n.t("common.error"), { root: true });
          } else if (ApiHelper.checkForConflict(res.response)) {
            dispatch("alert/error", i18n.t("validation.deveuiConflict"), {
              root: true,
            });
          }

          commit("LOADING", false);
        });
    },

    async updateTag(
      { dispatch, commit, rootState },
      { payload, fetch = true }
    ) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await TagRepository.update(user, payload)
        .then((tag) => {
          commit("CREATED_TAG", tag.data);
          commit("CLEAR_ERRORS");
          if (fetch) {
            dispatch("getTags", user);
          }
          dispatch("alert/success", i18n.t("common.updated"), {
            root: true,
          });
        })
        .catch((res) => {
          commit("SET_ERRORS", res.response.data.errors);

          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async deleteTag({ dispatch, commit, rootState }, { tagId }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      let success = false;
      await TagRepository.delete(user, tagId)
        .then(() => {
          commit("DELETED_TAG");
          dispatch("getTags", user);
          dispatch("alert/success", i18n.t("common.deleted"), { root: true });
          success = true;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });

      return success;
    },

    async setTag({ commit }, tag) {
      commit("SET_TAG", tag);
    },

    async getKeysForTag({ dispatch, commit, rootState }, { tagId }) {
      commit("LOADING", true);
      await dispatch("tagData/setTagDataKeys", [], { root: true });

      await TagRepository.getKeys(rootState.users.currentUser, tagId)
        .then((keys) => {
          dispatch("tagData/setTagDataKeys", keys.data, { root: true });
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getKeysForTagAsync({ dispatch, commit, rootState }, { tagId }) {
      commit("LOADING", true);

      return await TagRepository.getKeys(rootState.users.currentUser, tagId)
        .then((keys) => {
          commit("LOADING", false);
          return keys.data;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
          return [];
        });
    },

    async getDetails({ dispatch, commit, rootState }, deveui) {
      var user = rootState.users.currentUser;
      return await TagRepository.getDetails(user, deveui)
        .then((d) => {
          commit("SET_DETAIL", d.data);
          return d.data;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }
        });
    },

    async getTagWithKeys({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;
      var tags = [];

      if (user.role === Roles.SuperAdmin) {
        await TagRepository.getByCompanyId(
          user,
          rootState.users.userCompany.companyId
        )
          .then((response) => {
            tags = response.data;
          })
          .catch((res) => {
            if (res.response.status === 401) {
              dispatch(
                "users/login",
                {
                  email: rootState.users.credentials.email,
                  password: rootState.users.credentials.password,
                },

                { root: true }
              );
            }

            dispatch("alert/error", i18n.t("common.error"), { root: true });
            commit("LOADING", false);
          });
      } else {
        await TagRepository.get(user)
          .then((response) => {
            tags = response.data;
          })
          .catch((res) => {
            if (res.response.status === 401) {
              dispatch(
                "users/login",
                {
                  email: rootState.users.credentials.email,
                  password: rootState.users.credentials.password,
                },

                { root: true }
              );
            }

            dispatch("alert/error", i18n.t("common.error"), { root: true });
            commit("LOADING", false);
          });
      }

      if (tags.length > 0) {
        for (var i = 0; i < tags.length; i++) {
          await TagRepository.getKeys(user, tags[i].deveui).then((response) => {
            var keys = [];
            response.data.forEach((e) => {
              keys.push({ name: e.key });
            });
            tags[i].keys = keys;
          });

          tags[i].name = tags[i].deveui + " - " + tags[i].name;
        }
      }

      commit("LOADING", false);
      commit("SET_TAG_WITH_KEY", tags);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },

    async uploadTagImage({ dispatch, commit, rootState }, { payload, tagId }) {
      commit("LOADING", true);

      await TagRepository.uploadImage(
        rootState.users.currentUser,
        tagId,
        payload
      )
        .then(() => {
          commit("LOADING", false);
          dispatch("getTags", rootState.user.currentUser);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async deleteImage({ dispatch, commit, rootState }, { tagId }) {
      commit("LOADING", true);

      await TagRepository.deleteImage(rootState.users.currentUser, tagId)
        .then(() => {
          commit("LOADING", false);
          dispatch("getTags", rootState.user.currentUser);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async setDefaultKey({ dispatch, commit, rootState }, { tagId, key }) {
      commit("LOADING", true);

      await TagRepository.setDefaultKey(rootState.users.currentUser, tagId, key)
        .then(() => {
          commit("LOADING", false);
          dispatch("getTags");

          // Get the tags and batterystatuses
          dispatch("companies/getBatteryStatuses", null, { root: true });
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async addTagKey({ dispatch, commit, rootState }, { payload }) {
      commit("LOADING", true);

      await TagRepository.addTagKey(rootState.users.currentUser, payload)
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async updateTagKey({ dispatch, commit, rootState }, { payload }) {
      commit("LOADING", true);

      await TagRepository.updateTagKey(rootState.users.currentUser, payload)
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async deleteTagKey({ dispatch, commit, rootState }, { tagId, key }) {
      commit("LOADING", true);

      await TagRepository.deleteTagKey(rootState.users.currentUser, tagId, key)
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getKeyLabel({ dispatch, commit, rootState }, { tagId, key }) {
      commit("LOADING", true);

      return await TagRepository.getKeyLabel(
        rootState.users.currentUser,
        tagId,
        key
      )
        .then((res) => {
          commit("LOADING", false);
          commit("SET_LABEL", res.data);
          return res.data;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async getTagPool({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      await TagRepository.getTagPool(rootState.users.currentUser)
        .then((tags) => {
          commit("LOADING", false);
          commit("SET_TAGPOOL", tags.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    async updateTagPoolStatus({ dispatch, commit, rootState }, deveui) {
      commit("LOADING", true);
      await TagRepository.updateTagPoolStatus(
        rootState.users.currentUser,
        deveui
      )
        .then(() => {
          dispatch("getTagPool");
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
        });
    },

    setBatteryStatuses({ commit }, statuses) {
      commit("SET_BATTERY_STATUSES", statuses);
    },

    async getTagsWithInformation({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await companyRepository
        .getTagsWithInformation(user, rootState.users.userCompany.companyId)
        .then((res) => {
          commit("SET_TAGS_WITH_INFORMATION", res.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }
        });
      commit("LOADING", false);
    },

    async getLatestForCompany({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await dispatch("getTags", user);

      var payload = [];
      var tags = this.state.tag.tags;
      await companyRepository
        .getLatestTagData(user, rootState.users.userCompany.companyId)
        .then((res) => {
          for (var i = 0; i < tags.length; i++) {
            payload[i] = tags[i];
            payload[i].data = [];

            res.data.forEach((d) => {
              if (d.tagDeveui == payload[i].deveui) {
                if (d.key == "rssi") payload[i].rssi = d.value;
                else if (d.key == "vdd") payload[i].vdd = d.value;
                else {
                  payload[i].data.push(d);
                }

                if (
                  payload[i].latestTime == undefined ||
                  d.createdAt > payload[i].latestTime
                )
                  payload[i].latestTime = d.createdAt;
              }
            });
          }
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },

              { root: true }
            );
          }
        });
      commit("GET_TAGS", payload);

      commit("LOADING", false);
    },

    async getDecoders({ commit, rootState }) {
      var user = rootState.users.currentUser;
      await TagRepository.getDecoders(user)
        .then((d) => {
          commit("SET_DECODERS", d.data);
        })
        .catch(() => {
          commit("SET_DECODERS", DecoderHelper.decoders);
        });
    },

    async setTagData({ dispatch, commit, rootState }, { tagId, payload }) {
      commit("LOADING", true);

      await TagRepository.setData(rootState.users.currentUser, tagId, payload)
        .then(() => {
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
          commit("LOADING", false);
        });
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    SET_TAGPOOL(state, tagPool) {
      state.tagsInPool = tagPool;
    },

    SET_BATTERY_STATUSES(state, statuses) {
      state.batteryStatuses = statuses;
    },

    GET_TAGS(state, tags) {
      state.tags = tags;
    },

    CREATED_TAG(state, tag) {
      state.currentTag = tag;
    },

    DELETED_TAG(state) {
      state.currentTag = {};
    },

    SET_TAG(state, tag) {
      state.currentTag = tag;
    },

    SET_LABEL(state, label) {
      state.currentLabel = label;
    },

    SET_TAG_WITH_KEY(state, tags) {
      state.tagsWithKeys = tags;
    },

    SET_ERRORS(state, errors) {
      state.apiErrors = errors;
    },

    CLEAR_ERRORS(state) {
      state.apiErrors = [];
    },

    SET_DECODERS(state, decoders) {
      state.decoders = decoders;
    },

    SET_TAGS_WITH_INFORMATION(state, data) {
      state.tagsWithInformation = data;
    },

    SET_DETAIL(state, data) {
      state.detail = data;
    },

    // eslint-disable-next-line
    RESET_STATE(state) {
      state = {
        currentTag: {},
        tags: [],
        status: {
          loading: false,
        },
      };
    },
  },
};
