import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
import MetricHelper from "@/_helpers/metricHelper.js";
const TagDataRepository = RepositoryFactory.get("tagdata");
const CompanyRepository = RepositoryFactory.get("companies");
const ModuleRepository = RepositoryFactory.get("module");

export const tagData = {
  namespaced: true,
  state: {
    currentTagData: {},
    currents: [],
    tagData: [],
    tagsWithData: [],
    keys: [],
    tdKeys: [],
    defaults: {},
    meta: {},
    tagDataCount: null,
    hasDefaults: false,
    status: {
      loading: false,
      dataLoading: false,
    },
  },

  actions: {
    async getTagData({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      await TagDataRepository.get(user)
        .then((tagData) => {
          commit("GET_TAG_DATA", tagData.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
          commit("LOADING", false);
        });
    },

    async getTagDataKeys({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      var company = rootState.users.userCompany;

      await TagDataRepository.getKeys(user, company.companyId)
        .then((keys) => {
          commit("GET_TAG_DATA_KEYS", keys.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
          commit("LOADING", false);
        });
    },

    async getTagDataWithMetrics({ dispatch, commit, rootState }, { metrics }) {
      commit("LOADING", true);
      commit("TAGLOADING", true);

      var user = rootState.users.currentUser;

      var company = rootState.users.userCompany;

      return await CompanyRepository.getTagDataWithMetrics(
        user,
        company.companyId,
        MetricHelper.getMetricsUrlParams(metrics)
      )
        .then((tagData) => {
          commit("GET_TAG_DATA", tagData.data);
          commit("TAGLOADING", false);
          commit("LOADING", false);
          return tagData.data;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
          commit("LOADING", false);
          commit("TAGLOADING", false);
        });
    },

    async getTagDataAsync({ dispatch, rootState }, { metrics }) {
      var user = rootState.users.currentUser;

      var company = rootState.users.userCompany;

      let tagDatas = await CompanyRepository.getTagDataWithMetrics(
        user,
        company.companyId,
        MetricHelper.getMetricsUrlParams(metrics)
      ).catch(() => {
        dispatch("alert/error", i18n.t("common.error"), { root: true });
      });

      return tagDatas !== undefined ? tagDatas.data : [];
    },

    async getTagDataWithMultiTag({ dispatch, commit, rootState }, { metrics }) {
      commit("LOADING", true);
      commit("TAGLOADING", true);

      var user = rootState.users.currentUser;

      var company = rootState.users.userCompany;

      await CompanyRepository.getTagDataWithMetrics(
        user,
        company.companyId,
        MetricHelper.getMetricsUrlParams(metrics)
      )
        .then((tagData) => {
          commit("GET_TAG_DATA_MULTI", tagData.data);
          commit("TAGLOADING", false);
          commit("LOADING", false);
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
          commit("LOADING", false);
          commit("TAGLOADING", false);
        });
    },

    async getTagDataWithMultiTagAsync({ dispatch, rootState }, { metrics }) {
      var user = rootState.users.currentUser;

      var company = rootState.users.userCompany;

      let td = await CompanyRepository.getTagDataWithMetrics(
        user,
        company.companyId,
        MetricHelper.getMetricsUrlParams(metrics)
      ).catch((res) => {
        if (res.response.status === 401) {
          dispatch(
            "users/login",
            {
              email: rootState.users.credentials.email,
              password: rootState.users.credentials.password,
            },
            { root: true }
          );
        }

        dispatch("alert/error", i18n.t("common.error"), { root: true });
      });

      return td !== undefined ? td.data : [];
    },

    async getTagDataDefaults({ dispatch, commit, rootState }) {
      commit("LOADING", true);

      var user = rootState.users.currentUser;

      var company = rootState.users.userCompany;

      await CompanyRepository.getTagDataDefaults(user, company.companyId)
        .then((defaults) => {
          commit("GET_DEFAULTS", defaults.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
          commit("LOADING", false);
        });
    },

    async resetDefaults({ commit }) {
      commit("RESET_DEFAULTS");
    },

    async setTagDataDefaults({ commit }, { defaults }) {
      commit("SET_DEFAULTS", defaults);
    },

    async getTagdataDatapoints({ dispatch, commit, rootState }) {
      var user = rootState.users.currentUser;
      let metrics = {
        from: new Date(
          new Date().getTime() - 24 * 60 * 60 * 1000
        ).toISOString(),
      };
      let points = 0;
      var company = rootState.users.userCompany;
      await CompanyRepository.getTagDataWithMetrics(
        user,
        company.companyId,
        MetricHelper.getMetricsUrlParams(metrics)
      )
        .then((count) => {
          points = count.data.length;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          commit("LOADING", false);
        });

      return points;
    },

    async getTagDataForModule({ dispatch, rootState }, { moduleId, metrics }) {
      var user = rootState.users.currentUser;

      return await ModuleRepository.getTagDataForModule(
        user,
        moduleId,
        MetricHelper.getMetricsUrlParams(metrics)
      )
        .then((tagData) => {
          return tagData.data;
        })
        .catch(() => {
          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async getTagDataForModuleByLimit(
      { dispatch, rootState },
      { moduleId, limit }
    ) {
      var user = rootState.users.currentUser;

      return await ModuleRepository.getTagDataForModuleByLimit(
        user,
        moduleId,
        limit
      )
        .then((tagData) => {
          return tagData.data;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async getLatestTagDataForModule({ dispatch, rootState }, { moduleId }) {
      var user = rootState.users.currentUser;

      return await ModuleRepository.getLatestTagDataForModule(user, moduleId)
        .then((tagData) => {
          return tagData.data;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async setTagDataKeys({ commit }, keys) {
      commit("GET_TAG_DATA_KEYS", keys);
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },

    clearTagData({ commit }) {
      commit("CLEAR_TAGDATA");
    },

    async getDatapointsLastWeek({ rootState, dispatch }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany;

      return await CompanyRepository.getDatapointsLastWeek(
        user,
        company.companyId
      )
        .then((data) => data.data)
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async getDatapointsLastDay({ rootState, dispatch }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany;

      return await CompanyRepository.getDatapointsLastDay(
        user,
        company.companyId
      )
        .then((data) => data.data)
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async getSensorDatapointsLastDay({ rootState, dispatch }) {
      var user = rootState.users.currentUser;
      var company = rootState.users.userCompany;

      return await CompanyRepository.getSensorDatapointsLastDay(
        user,
        company.companyId
      )
        .then((data) => data.data)
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async getTrendDataForTag(
      { dispatch, rootState },
      { tagId, tagKey, metrics }
    ) {
      var user = rootState.users.currentUser;

      return await TagDataRepository.getTrendDataForTag(
        user,
        tagId,
        encodeURIComponent(tagKey),
        MetricHelper.getMetricsUrlParams(metrics)
      )
        .then((tagData) => {
          return tagData.data;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async getCurrentTagdata(
      { dispatch, commit, rootState },
      { tagId, tagKey }
    ) {
      var user = rootState.users.currentUser;

      return await TagDataRepository.getCurrentTagdata(
        user,
        tagId,
        encodeURIComponent(tagKey)
      )
        .then((tagData) => {
          commit("SET_CURRENT_TAGDATA", tagData.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },

    async getCurrentsForTag({ commit, rootState, dispatch }, deveui) {
      var user = rootState.users.currentUser;
      await TagDataRepository.getCurrentsForDevice(user, deveui)
        .then((d) => {
          commit("SET_CURRENTS", d.data);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }

          dispatch("alert/error", i18n.t("common.error"), {
            root: true,
          });
        });
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    SET_META(state, meta) {
      state.meta = meta;
    },

    TAGLOADING(state, status) {
      state.status.dataLoading = status;
    },

    SET_TAG_DATA(state, tagData) {
      state.tagData = tagData;
    },

    SET_CURRENT_TAGDATA(state, tagData) {
      state.currentTagData = tagData;
    },

    SET_CURRENTS(state, currents) {
      state.currents = currents;
    },

    GET_TAG_DATA(state, tagData) {
      state.tagData = tagData;

      // Sätt nycklarna som tillhör tagdatan
      var tdKeys = tagData.map((x) => x.key);
      state.tdKeys = [...new Set(tdKeys)];
    },

    GET_TAG_DATA_MULTI(state, tagData) {
      state.tagsWithData = tagData;
    },

    GET_TAG_DATA_KEYS(state, keys) {
      state.keys = keys;
    },

    GET_DEFAULTS(state, defaults) {
      state.defaults = defaults;
      state.hasDefaults = true;
    },

    SET_DEFAULTS(state, defaults) {
      state.defaults = {};
      state.defaults = defaults;
      state.hasDefaults = true;
    },

    GET_TOTAL_DP(state, dp) {
      state.tagDataCount = dp;
    },

    RESET_DEFAULTS(state) {
      state.defaults = {};
      this.hasDefaults = false;
    },

    // eslint-disable-next-line
    RESET_STATE(state) {
      state = {
        currentTagData: {},
        tagData: [],
        keys: [],
        defaults: {},
        tagDataCount: null,
        hasDefaults: false,
        status: {
          loading: false,
        },
      };
    },

    CLEAR_TAGDATA(state) {
      state.tagData = [];
      state.keys = [];
    },
  },
};
